/* eslint-disable @next/next/no-img-element */
import type { NextPage } from "next";
import { useRouter } from "next/router";
import Typography from "@/components/Typography";
import Button from "@/components/Button";
import styles from "./index.module.scss";

const Custom404Page: NextPage = () => {
  const { push } = useRouter();
  const goToDashboard = () => {
    push("/experience/dashboard");
  };
  return (
    <div className={styles.root}>
      <img src="/images/404-image.svg" alt="404" />
      <img src="/images/404-text.svg" alt="404" className={styles.textImage} />
      <Typography variant="h6">Something went wrong!</Typography>
      <Typography
        variant="body1"
        color="text.secondary"
        className={styles.description}
      >
        {`This page doesn't exist or was removed! We suggest you back to dashboard`}
      </Typography>
      <Button onClick={goToDashboard}>Back to dashboard</Button>
    </div>
  );
};

export default Custom404Page;
